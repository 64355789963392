import type { GroupDTO } from "$lib/server/entities/group/GroupEntity";
import type { GroupMemberView } from "$lib/server/group/group_service";

class GroupMembershipStore {

    private _joined: GroupMemberView[] = $state([]);
    private _pending: GroupMemberView[] = $state([]);

    initialize(data: GroupMemberView[]) {

        // console.log("Initialized groups with: ", data);
        this._joined = data.filter(item => item.membership === 'user' || item.membership === 'admin');
        this._pending = data.filter(item => item.membership === 'pending');
    } 

    get joined_views(): GroupMemberView[] {
        return this._joined;
    }

    get joined_groups(): GroupDTO[] {
        return this._joined.map(item => item.group);
    }
}

const group_membership_store = new GroupMembershipStore();
export default group_membership_store;